<template>
  <div
    id="account-actions-mobile"
    class="x-button-actions"
  >
    <div class="-outer-wrapper">
      <div class="-left-wrapper">
        <router-link
          class="-item-wrapper -line ng-star-inserted"
          :to="{ name: 'invite-friend' }"
        >
          <picture>
            <img
              src="@/assets/newImg/affiliate.png"
              alt="รูปไอคอนไลน์"
              width="34"
              height="34"
              class="-ic-img"
            >
          </picture>
          <span class="-text">สร้างรายได้</span>
        </router-link>
        <router-link
          class="-item-wrapper -promotion"
          :to="{ name: 'promotions' }"
        >
          <picture>
            <img
              src="@/assets/newImg/ic-mobile-left-2.webp"
              alt="รูปไอคอนไลน์"
              width="34"
              height="34"
              class="-ic-img"
            >
          </picture>
          <span class="-text">โปรโมชั่น</span>
        </router-link>
      </div>
      <router-link
        class="-center-wrapper"
        :to="{ name: 'home' }"
      >
        <div
          class="-selected"
          style="background-image: url(&quot;/dice.png&quot;);"
        ><img
          src="@/assets/newImg/curve-top.png"
          alt=" คาสิโนออนไลน์ระดับโลก รวม คาสิโน บาคาร่าสด สล็อต และกีฬา"
          class="-top-curve ls-is-cached lazyloaded"
        ><img
          src="@/assets/newImg/curve-bottom.png"
          data-src="assets/curve-bottom.png"
          alt=" คาสิโนออนไลน์ระดับโลก รวม คาสิโน บาคาร่าสด สล็อต และกีฬา"
          class="-bottom-curve ls-is-cached lazyloaded"
        ></div>
      </router-link>
      <div class="-fake-center-bg-wrapper">
        <svg viewBox="-10 -1 30 12">
          <defs>
            <linearGradient
              id="rectangleGradient"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop
                offset="20%"
                stop-color="#B60201"
              />
              <stop
                offset="100%"
                stop-color="#040000"
              />
            </linearGradient>
          </defs>
          <path d="M-10 -1 H30 V12 H-10z M 5 5 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0z" />
        </svg>
      </div>
      <div class="-right-wrapper ng-star-inserted">
        <router-link
          class="-item-wrapper -shimmer -register"
          :to="{ name: 'deposit' }"
        >
          <picture><img
            src="@/assets/newImg/ic-mobile-right-1.webp"
            alt=" รูปไอคอนไลน์"
            width="34"
            height="34"
            class="-ic-img"
          >
          </picture><span class="-text d-none d-sm-inline-block">ฝากเงิน</span><span
            class="-text d-sm-none"
          >ฝากเงิน</span>
        </router-link>
        <router-link
          class="-item-wrapper -login"
          :to="{ name: 'withdraw' }"
        >
          <picture><img
            src="@/assets/newImg/ic-mobile-right-2.webp"
            alt=" รูปไอคอนไลน์"
            width="34"
            height="34"
            class="-ic-img"
          >
          </picture><span class="-text d-none d-sm-inline-block">ถอนเงิน</span><span
            class="-text d-sm-none"
          >ถอนเงิน</span>
        </router-link>
      </div><!---->
      <div class="-fully-overlay js-footer-lobby-overlay" />
    </div>

    <div class="contact-line">
      <a :href="agent.linecontact">
        <img
          src="/line.png"
          alt="line"
          height="45px"
        >
      </a>
    </div>
  </div>
</template>

<script>
// import { BLink } from 'bootstrap-vue'

export default {
  components: {
    // BLink,
  },
  data() {
    return {
      agent: {},
    }
  },
  mounted() {
    this.GetAgentData()
  },
  methods: {
    GetAgentData() {
      this.$http
        .get('/agent/show')
        .then(response => {
          this.agent = response.data
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.contact-line {
    position: fixed;
    bottom: 11%;
    right: 10px;
    /* height: 35px;
    width: 40px; */
    z-index: 999;
    justify-content: center;
    align-items: center;
    display: flex;
    /* background: #fff; */
    border-radius: 50%;
    /* padding-left: 10px; */
    box-shadow: 0 0 10px 0 rgba(60, 238, 193, 0.527);
    transform: scale(1);
    animation: pulse-blue 2s infinite;
}
@keyframes pulse-blue {
    0% {
        transform: scale(0.90);
        box-shadow: 0 0 0 0 rgba(2, 197, 18, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(87, 255, 171, 0.103);
    }

    100% {
        transform: scale(0.90);
        box-shadow: 0 0 0 0 rgba(69, 231, 69, 0);
    }
}

.container-sm {
  max-width: 500px !important;
}
</style>
